<template>
    <v-card>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-list nav class="pa-0" color="transparent">
                    <v-list-item @click="gotoAssignment(assignmentData.bid)" v-on="on">
                        <v-list-item-icon v-if="isMasterInstance">
                            <v-icon class="mr-2" large color="secondary">assignment</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-else-if="!isMasterInstance">
                            <v-icon class="mr-2" v-if="assignmentData.handins.length" large color="success">assignment_turned_in</v-icon>
                            <v-icon class="mr-2" large v-else-if="isAssignmentOverdue(assignmentData.allow_handins_until)" color="warning">
                                assignment_late
                            </v-icon>
                            <v-icon class="mr-2" large v-else color="orange">assignment_late</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <span class="secondary--text subtitle-1 font-weight-bold">
                                    {{ assignmentData.long_id }}
                                </span>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <div class="d-flex align-center caption">Due on {{ assignmentData.allow_handins_until | dateTimeToHuman }}</div>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
            <div v-if="!isMasterInstance">
                <div v-if="assignmentData.handins.length" class="d-flex flex-column">
                    <span>Submitted on {{ assignmentData.handins[0].handin_timestamp | dateTimeToHuman }}</span>
                    <span class="caption font-weight-bold">Click to see all submissions</span>
                </div>
                <span v-else-if="isAssignmentOverdue(assignmentData.allow_handins_until)">Overdue without submission</span>
                <span v-else>Submission due</span>
            </div>
            <div v-else>Open assignment</div>
        </v-tooltip>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { assignmentMethods } from '@/mixins/assignments'

export default {
    name: 'AssignmentTile',
    mixins: [assignmentMethods],
    props: {
        assignmentData: Object
    },
    computed: {
        ...mapGetters('instanceStore', ['isMasterInstance'])
    }
}
</script>
